<template>
  <v-app-bar color="#1c3764" class="rounded-0 elevation-2" height="99">
    <v-row>
      <v-col cols="5" md="6" class="d-flex">
        <v-img
          src="/img/banner.png"
          alt="Logo" class="ml-4"
          contain
          width="400"
          height="80"
        ></v-img>
      </v-col>

      <v-spacer></v-spacer>
      <v-col cols="4" md="2" :class="!$vuetify.display.mobile?'text-right':''" class="my-auto"> 
        <v-menu   
          v-model="closeLanguage"
          location="bottom"
           transition="scale-transition"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ props }">
            <v-btn
              rounded
              outlined variant="flat"
              class="notranslate px-2"
              style="text-transform: none; "
              v-bind="props"
            > 
              <country-flag
                style="margin-top: -8px; max-width: 50px; height: 40px;"
                class="rounded-xl"
                :country="currentLanguage.flag"
              />
              &nbsp; {{ currentLanguage.title }}
            </v-btn>
          </template>

          <v-list>
            <!-- Use v-show or v-if to manage component lifecycle -->
            <v-list-item color="white">
              <translatorForm 
                :countries="store.state.list_languages"
              />
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-app-bar>
</template>


<script lang="ts">  
import { defineComponent, computed, ref, watch  } from 'vue';
import { useStore } from 'vuex';
import translatorForm from './Translator.vue';
import countryFlag from 'vue-country-flag-next'
  
export default defineComponent({
  name: 'App',
  components: {
    translatorForm, countryFlag
  },
  setup() {
    const store = useStore();
    const closeLanguage = ref(false); 
    const componentKey = ref(0);  // Dynamic key to trigger re-render

    const currentLanguage = computed(() => {
    const selectedLang = store.state.list_languages.find(
        (lang: { code: string }) => lang.code.split('|')[1] === store.state.lang
      );
      return selectedLang || { title: 'English', code: 'en|en', flag: 'gb' };
    });

    watch(
      () => store.state.lang,
      (val) => {
        if (val) {
          closeLanguage.value = false;
        }
      }
    );
 

    return {  
      store,
      currentLanguage,
      closeLanguage, 
      componentKey, 
    };
  },
});

</script>

<style scoped>
.glass-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px); 
}
</style>

<style> 
.goog-te-spinner-pos, .skiptranslate, .goog-te-spinner-frame, .goog-te-banner-frame.skiptranslate {
    display: none !important;
}
</style>
