<template> 
    <!-- Footer -->
     <div>
    <v-footer color="#1c3764"   class="pa-4">
      <v-container>
        <br>
        <v-row>
          <!-- Support or Contact Us -->
          <v-col cols="12" md="4">
            <h4 class="font-weight-bold">Support or Contact Us</h4>
            <p>Have questions or need help? Reach out to us anytime!</p><br>
                <v-btn variant="text"> <v-icon>mdi-email</v-icon></v-btn> support@brick-work-usa <br>
                <v-btn variant="text"> <v-icon>mdi-phone</v-icon></v-btn> +1 (212) 555-6789
          </v-col>

          <!-- Map -->
          <v-col cols="12" md="5">
            <h4 class="font-weight-bold">Our Location</h4>
            123 Main Street,
            New York, NY 10001,
            United States
          </v-col>

          <!-- Email Us -->
          <v-col cols="12" md="3">
            <h4 class="font-weight-bold">Our Socials</h4>
             <v-btn variant="text"> <v-icon size="30">mdi-facebook</v-icon></v-btn>
             <v-btn variant="text"> <v-icon size="30">mdi-linkedin</v-icon></v-btn>
             <v-btn variant="text"> <v-icon size="30">mdi-instagram</v-icon></v-btn>   
          </v-col>
        </v-row>
      </v-container>  
    </v-footer> 
    <v-card   class="pa-4">
      <v-row  style="background-color: #e3edff;  " :class="$vuetify.display.mobile?'text-caption':''" > 
        <v-spacer></v-spacer>
            <v-col cols="12" md="8">
                <strong>Disclaimer:</strong> We are not a competent office of any Government or representative of any Government Embassies, Consulates, or Missions. We are not a law firm, and this website does not offer legal advice, explanations, opinions, or recommendations regarding legal rights.
            </v-col> 
            <v-spacer></v-spacer>
          </v-row>
      <v-row>
          <v-col cols="12" class="text-center "> 
                 <small>2024 @ brick-work-usa.us  </small> 
          </v-col>
      </v-row>
    </v-card>
    
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FooterComponent',
});
</script>

<style scoped>
h4 {
  margin-bottom: 1rem;
  color: #ffffff; /* Optional: Adjust the heading color */
}
p {
  color: #ddd; /* Optional: Adjust text color for better contrast */
}
</style>
