// store.ts
import { createStore } from 'vuex';

export interface State {
  lang: string | null;
  country: string | null; 
  list_countries: object;
  list_languages: object;
}

const store = createStore<State>({
  state: {
    lang: 'en',
    country: '',
    list_countries: [
     'Las Vegas, Nevada', 'New York', 'Los Angeles, California'
    ],
    list_languages: [ 
        { code: 'en|af', title: 'Afrikaans', flag: 'za' }, // South Africa
        { code: 'en|sq', title: 'Albanian', flag: 'al' }, // Albania
        { code: 'en|ar', title: 'Arabic', flag: 'sa' }, // Saudi Arabia
        { code: 'en|hy', title: 'Armenian', flag: 'am' }, // Armenia
        { code: 'en|az', title: 'Azerbaijani', flag: 'az' }, // Azerbaijan
        { code: 'en|eu', title: 'Basque', flag: 'es' }, // Spain
        { code: 'en|be', title: 'Belarusian', flag: 'by' }, // Belarus
        { code: 'en|bg', title: 'Bulgarian', flag: 'bg' }, // Bulgaria
        { code: 'en|ca', title: 'Catalan', flag: 'ad' }, // Spain
        { code: 'en|zh-CN', title: 'Chinese (Simplified)', flag: 'cn' }, // China
        { code: 'en|zh-TW', title: 'Chinese (Traditional)', flag: 'tw' }, // Taiwan
        { code: 'en|hr', title: 'Croatian', flag: 'hr' }, // Croatia
        { code: 'en|cs', title: 'Czech', flag: 'cz' }, // Czech Republic
        { code: 'en|da', title: 'Danish', flag: 'dk' }, // Denmark
        { code: 'en|nl', title: 'Dutch', flag: 'nl' }, // Netherlands
        { code: 'en|en', title: 'English', flag: 'gb' }, // United Kingdom
        { code: 'en|et', title: 'Estonian', flag: 'ee' }, // Estonia
        { code: 'en|tl', title: 'Filipino', flag: 'ph' }, // Philippines
        { code: 'en|fi', title: 'Finnish', flag: 'fi' }, // Finland
        { code: 'en|fr', title: 'French', flag: 'fr' }, // France
        { code: 'en|de', title: 'German', flag: 'de' }, // Germany
        { code: 'en|el', title: 'Greek', flag: 'gr' }, // Greece
        { code: 'en|hu', title: 'Hungarian', flag: 'hu' }, // Hungary
        { code: 'en|id', title: 'Indonesian', flag: 'id' }, // Indonesia
        { code: 'en|ga', title: 'Irish', flag: 'ie' }, // Ireland
        { code: 'en|it', title: 'Italian', flag: 'it' }, // Italy
        { code: 'en|ja', title: 'Japanese', flag: 'jp' }, // Japan
        { code: 'en|ko', title: 'Korean', flag: 'kr' }, // South Korea
        { code: 'en|lt', title: 'Lithuanian', flag: 'lt' }, // Lithuania
        { code: 'en|ms', title: 'Malay', flag: 'my' }, // Malaysia
        { code: 'en|no', title: 'Norwegian', flag: 'no' }, // Norway
        { code: 'en|pl', title: 'Polish', flag: 'pl' }, // Poland
        { code: 'en|pt', title: 'Portuguese', flag: 'pt' }, // Portugal
        { code: 'en|ro', title: 'Romanian', flag: 'ro' }, // Romania
        { code: 'en|ru', title: 'Russian', flag: 'ru' }, // Russia
        { code: 'en|es', title: 'Spanish', flag: 'es' }, // Spain
        { code: 'en|sv', title: 'Swedish', flag: 'se' }, // Sweden
        { code: 'en|th', title: 'Thai', flag: 'th' }, // Thailand
        { code: 'en|tr', title: 'Turkish', flag: 'tr' }, // Turkey
        { code: 'en|uk', title: 'Ukrainian', flag: 'ua' }  // Ukraine 
      
    ]
  },
  mutations: {
    setLang(state, lang: string) {
      state.lang = lang; // Mutate the state directly here
    },
  },
  actions: {
    changeLang({ commit }, lang: string) {
      commit('setLang', lang); // Call the mutation inside an action
    },

    findWebsite({ state }){
        switch (state.country) {
          case 'Las Vegas, Nevada':
              window.location.href = 'https://usa-esta.eta.it.com/las-vegas/'
            break;
            case 'New York':
              window.location.href = 'https://usa-esta.eta.it.com/new-york/'
            break;
            case 'Los Angeles, California':
              window.location.href = 'https://usa-esta.eta.it.com/california/' 
            break;
          default: break;
        }
    }


  },

  
  getters: {
    lang: (state) => state.lang, 
    country: (state) => state.country, 
  },
});

export default store;
