
<template>
    <div> 
    <v-row no-gutters v-if="showCards"  class="sticky-cards"> 
      <v-col cols="12" >
        <v-form ref="form">
            <v-card class="py-2 pa-4 rounded-0 glass-card" color="#1c3764" >  
              <v-row no-gutters>
                <v-spacer></v-spacer>
                  <v-col cols="12" md="4"  >  
                    <b style="color: black"> START YOUR ESTA APPLICATION</b>

                    <v-select  v-model="store.state.country"
                    :rules="[rules.required]"  
                    @blur="form.value?.validate()"  density="compact" label="Select Country" hide-details rounded="lg"   variant="solo" :items="store.state.list_countries" >
                      <template v-slot:append>
                        <v-btn class="mb-2 mt-1 mr-0" color="primary" @click="viewCountry">Continue</v-btn>
                      </template>
                    </v-select>

                  </v-col> 
                  <v-spacer></v-spacer>
               </v-row>
            </v-card>
            </v-form>
          </v-col> 
        </v-row>  
    </div>
</template>
<script lang="ts">
import { defineComponent,  ref,  onMounted, onUnmounted  } from 'vue'; 
import { useStore } from 'vuex';
 

export default defineComponent({
  name: 'App', 
  setup() {
    const country = ref(null);
    const showCards = ref(false); 
    const store = useStore() 
    const form = ref(); 
    const rules = {
        required: (value: any) => !!value || 'This field is required.',
      };

    const handleScroll = () => {
      showCards.value = window.scrollY > 200; 
    };

    const viewCountry = () => {
        if (form.value?.validate()) {
          store.dispatch('findWebsite');
        } 
      };

    onMounted(() => {
      window.addEventListener('scroll', handleScroll); 
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });
 
    return {
      country,
      form,
      showCards, 
      viewCountry,
      store,rules,
    };
  },
});
</script>

<style scoped>
.sticky-cards {
  position: fixed;
  top: 99px;
  left: 0;
  right: 0;
  z-index: 10;
  color: #1c3764;
}
</style>
