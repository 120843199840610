<template>
  <div>
  <v-app >
    <navTop /><formTop/>
    <v-main>
      <div class="bg"></div>
      <router-view  style="min-height: 50rem; overflow: hidden;"/>
      <footerBottom/> 
    </v-main>
    <floatingButton/>
  </v-app>
</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import navTop from './NavTop.vue'
import footerBottom from './Footer.vue'
import floatingButton from './FloatingButton.vue'
import formTop from '../views/components/form.vue'

export default defineComponent({
  name: 'App',
  components: {
    navTop,footerBottom,formTop,floatingButton
  },
})
</script>

<style scoped>
/* Apply background image to the entire app */
.bg {
  background-image: url('../assets/img/bg.svg');
  background-size: cover; 
  width: 100%;
  margin-top: -30px; height: 51rem;
  position: fixed;
  z-index: -9999;
  
}

/* Make sure the body and html take up full height for proper scrolling */
html, body {
  height: 100%;
  margin: 0;
}

/* Ensure the v-app container fills the page and allows scrolling */
.v-application {
  min-height: 100%;
}

/* Allow content to scroll within the main container */
.v-main {
  overflow-y: auto; /* Enables scrolling when content overflows */
  position: relative;
  z-index: 1;
}
</style>

<style>
.glass-card {
  background: rgba(251, 240, 240, 0.756) !important; /* Adjust the color and opacity */
  backdrop-filter: blur(5px) !important;  
  color: black;
}
</style>
